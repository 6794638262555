"use client"

import React, { useState, useEffect, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from "./button";
import { CardContent, CardDescription, CardTitle } from "./card";

// Données des fonctionnalités
const features = [
  {
    name: "Une vue d'ensemble des actions à mener.",
    description:
      'Retrouvez en un seul écran toutes les actions effectuées ou restant à faire pour respecter vos obligations réglementaires.',
    image: '/Screenshot_overview.png',
  },
  {
    name: 'Gérer les températures des frigos.',
    description: 'Gérez les températures de vos frigos et congélateurs pour garantir la qualité de vos produits. Vous pouvez relever les températures manuellement ou les enregistrer automatiquement avec des sondes connectées.',
    image: '/Screenshot_temp.png',
  },
  {
    name: 'Contrôler les dates limites de consommation.',
    description: 'Vous pouvez contrôler à l\'avance les dates limites de consommation de vos produits pour éviter les pertes et garantir la qualité de vos produits. Vous pouvez aussi recevoir des alertes pour les produits proches de leur DLC.',
    image: '/Screenshot_dlc.png',
  },
];

// Composant pour le cadre de téléphone
const IPhoneFrame = ({ children }) => (
  <div className="relative w-[200px] h-[400px] sm:w-[250px] sm:h-[500px] md:w-[300px] md:h-[600px] rounded-[40px] sm:rounded-[50px] bg-black p-2 sm:p-3 shadow-xl">
    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-1/3 h-5 sm:h-7 bg-black rounded-b-2xl sm:rounded-b-3xl"></div>
    <div className="w-full h-full bg-white rounded-[32px] sm:rounded-[40px] overflow-hidden">
      {children}
    </div>
  </div>
);

export function Features() {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState(features[0].image);
  const [isImageChanging, setIsImageChanging] = useState(false);

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    const index = emblaApi.selectedScrollSnap();
    setSelectedIndex(index);
    setCurrentImage(features[index].image);
    setIsImageChanging(true);
    setTimeout(() => setIsImageChanging(false), 500); // Durée de l'animation
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return (
    <div className="overflow-hidden w-full bg-white py-12 sm:py-16"> {/* Réduit le padding vertical */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {/* Ajustement des marges pour remonter le texte */}
        <CardTitle className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2">Nos Fonctionnalités</CardTitle>
        <CardDescription className="text-sm sm:text-base text-gray-600 mb-6">Découvrez les fonctionnalités clés de notre application</CardDescription>

        <CardContent className="p-4 sm:p-6 relative">
          <div className="relative">
            {/* Ajout de padding latéral pour éviter que le carousel n'atteigne les zones des boutons */}
            <div className="overflow-hidden px-6" ref={emblaRef}>
              <div className="flex">
                {features.map((feature, index) => (
                  <motion.div
                    key={feature.name}
                    className="flex-[0_0_100%] min-w-0 relative px-2 sm:px-4"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{
                      opacity: selectedIndex === index ? 1 : 0.5,
                      y: selectedIndex === index ? 0 : 25,
                    }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                      {/* Cadre de téléphone avec image */}
                      <div className="sm:w-5/12 mb-6 sm:mb-0 flex justify-center">
                        <IPhoneFrame>
                          <AnimatePresence>
                            <motion.img
                              key={feature.image}
                              src={feature.image}
                              alt={feature.name}
                              className={`w-full object-fill h-full object-cover ${isImageChanging ? "animate-pulse" : ""}`}
                              initial={{ opacity: 0, y: 20 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -20 }}
                              transition={{ duration: 0.5 }}
                            />
                          </AnimatePresence>
                        </IPhoneFrame>
                      </div>
                      {/* Détails de la fonctionnalité */}
                      <div className="sm:w-7/12 sm:pl-8">
                        <h3 className="font-custom font-semibold text-gray-900 text-center sm:text-left">{feature.name}</h3>
                        <p className="font-custom text-gray-500 mt-2 text-center sm:text-left">{feature.description}</p>
                        <AnimatePresence mode="wait">
                          {/* Si vous avez des animations supplémentaires, vous pouvez les ajouter ici */}
                        </AnimatePresence>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
            {/* Boutons de navigation avec z-index augmenté */}
            <Button
              variant="outline"
              size="icon"
              className="absolute left-2 sm:left-4 top-1/2 transform -translate-y-1/2 bg-white/80 backdrop-blur-sm z-10"
              onClick={scrollPrev}
            >
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              size="icon"
              className="absolute right-2 sm:right-4 top-1/2 transform -translate-y-1/2 bg-white/80 backdrop-blur-sm z-10"
              onClick={scrollNext}
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
        </CardContent>
      </div>
    </div>
  );
}
