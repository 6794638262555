import { useState } from 'react'
import { motion } from 'framer-motion'
import { Calendar, Truck, Bell, CheckSquare, MessageSquare, Clock } from 'lucide-react'

const features = [
  {
    name: 'Contrôler les dates limites de consommation',
    description: 'Surveillez et gérez facilement les dates de péremption pour assurer la fraîcheur de vos produits.',
    icon: Calendar,
  },
  {
    name: 'Contrôle à la réception des marchandises',
    description: 'Simplifiez le processus de vérification des livraisons pour garantir la qualité des produits reçus.',
    icon: Truck,
  },
  {
    name: 'Soyez toujours informé',
    description: 'Recevez des alertes personnalisées pour rester au courant des tâches importantes et des échéances.',
    icon: Bell,
  },
  {
    name: 'Une vue détaillée des actions à mener',
    description: 'Suivez en temps réel les tâches à accomplir et celles déjà réalisées pour une gestion efficace.',
    icon: CheckSquare,
  },
  {
    name: 'Un agent intelligent pour vous accompagner',
    description: "Bénéficiez d'une assistance IA basée sur les normes HACCP pour une gestion optimale de la sécurité alimentaire.",
    icon: MessageSquare,
  },
  {
    name: 'Un historique facile à retrouver',
    description: 'Accédez rapidement à vos données archivées, conformément aux exigences légales de conservation.',
    icon: Clock,
  },
]

export function SecondaryFeatures() {
  const [hoveredFeature, setHoveredFeature] = useState(null)

  return (
    <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Simplifiez la gestion de vos obligations sanitaires
          </h2>
          <p className="text-xl text-gray-600">
            Notre solution intuitive vous aide à maintenir les normes d'hygiène les plus élevées, en toute simplicité.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={feature.name}
              className="bg-white rounded-xl shadow-lg overflow-hidden cursor-pointer transition-all duration-300 ease-in-out"
              whileHover={{ scale: 1.05, boxShadow: '0 10px 40px rgba(0,0,0,0.1)' }}
              onHoverStart={() => setHoveredFeature(index)}
              onHoverEnd={() => setHoveredFeature(null)}
            >
              <div className="p-8">
                <div className="w-16 h-16 bg-primary-100 rounded-full flex items-center justify-center mb-6">
                  <feature.icon className="w-8 h-8 text-primary-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-3">{feature.name}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
              <motion.div
                className="bg-primary-600 h-2"
                initial={{ width: '0%' }}
                animate={{ width: hoveredFeature === index ? '100%' : '0%' }}
                transition={{ duration: 0.3 }}
              />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}