export function Cta() {
    return (
      <div className="bg-[linear-gradient(190deg,var(--tw-gradient-stops))] from-[#EBF5FF] from-[5%] via-[#003366] via-[35%] to-[#003366] sm:bg-[linear-gradient(190deg,var(--tw-gradient-stops))]">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
          <h2 className="text-3xl text-left font-bold tracking-tight text-white sm:text-4xl">
            Envie d'essayer ?
            <br />
            Commencer maintenant et profiter de 15 jours gratuit pour vous faire votre avis !
          </h2>
          <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
            <a
              href="/signup"
              className="rounded-md bg-[#3399FF] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Commencer
            </a>
            

          </div>
        </div>
      </div>
    )
  }
  